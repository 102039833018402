.account {
  &__container {
    @include container-large;
  }

  &__header {
    @include level;

    margin-bottom: var(--spacing-4);
    padding-bottom: var(--spacing-4);

    border-bottom: 1px solid var(--colour-neutral-light-slate-25);

    &-actions {
      @include level;
      column-gap: var(--spacing-1);
    }
  }

  &__projects {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: var(--spacing-3);
    row-gap: var(--spacing-3);

    &--empty {
      display: grid;
      justify-items: center;
      text-align: center;
      row-gap: 2rem;
    }

    @media screen and (max-width: 520px) {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }
  }

  &__project {
    @include box-large;
    overflow: hidden;

    padding: var(--spacing-3);

    &-header {
      margin-bottom: var(--spacing-2);

      .h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        line-height: 1.2;
      }
    }

    &-content {
      margin-bottom: var(--spacing-2);
    }

    &-actions {
      @include level;

      padding-top: var(--spacing-2);
      border-top: 1px solid var(--colour-neutral-light-slate-25);

      & > div {
        display: grid;
        align-content: center;
        grid-auto-flow: column;
        column-gap: var(--spacing-2);
      }

      svg {
        height: 1.25rem;
      }

      a {
        font-weight: bold;

        small {
          font-weight: normal;
        }
      }
    }

    &.--disabled {
      background-color: var(--colour-neutral-cloud);
      color: var(--colour-neutral-light-slate-100);
    }
  }
}
