.entry-form {
  max-width: 27rem;
  width: 100%;

  &__heading {
    margin-bottom: var(--spacing-5);
  }

  &__separator {
    margin: var(--spacing-3) 0;
    text-align: center;
  }

  &__intro {
    margin-bottom: var(--spacing-3);
  }

  &__message {
    margin-bottom: var(--spacing-2);
  }

  &__forgot-password {
    margin-bottom: var(--spacing-2);
  }

  &__input {
    display: grid;
    grid-auto-flow: row;
    row-gap: var(--spacing-2);
  }

  &__footer {
    @include level;

    margin-top: var(--spacing-3);
  }

  &__social {
    display: grid;
  }

  &__google {
    @include button-reset;

    display: grid;

    grid-template-columns: auto auto;
    justify-content: center;

    background-color: var(--button-background-colour-tertiary);
    color: var(--button-colour-tertiary);
  }
}
