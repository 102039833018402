@import "~pokko-shared/style.scss";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1;
}

body {
  color: var(--colour-text);
  background-color: var(--colour-neutral-cloud);

  transition: all 200ms linear;

  &.is-white {
    background-color: #fff;
  }
}

// body.is-dark {
//   color: #fff;
//   background-color: var(--colour-neutral-onyx);
// }

hr {
  margin: 1rem 0;
  border: none;
  height: 1px;
  background-color: var(--colour-neutral-light-slate-25);
}

a {
  color: var(--colour-link);

  &:hover {
    color: var(--colour-link-hover);
  }
}

pre {
  max-width: 100%;
  overflow: auto;
}

ul,
ol {
  list-style-type: none;
}

::selection {
  background: var(--colour-primary-green-25);
}

@import "./components/index";
