html {
  font-size: var(--font-size-base);
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html,
body,
input,
textarea,
button {
  font-family: var(--font-family), sans-serif;
}

pre,
code {
  font-family: var(--font-family-mono), monospace;
}

strong {
  font-weight: bold;
}

small {
  font-size: var(--font-size-small);
}

.title {
  font-size: 4rem;
  font-weight: bold;
  letter-spacing: -0.01em;
}

.h2 {
  font-size: 2.5rem;
  font-weight: bold;
  letter-spacing: -0.01em;
}

.h3 {
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: -0.01em;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: normal;
}

.header {
  font-size: var(--font-size-small);
  font-weight: bold;
  letter-spacing: 0em;
  text-transform: uppercase;

  &.is-light {
    color: var(--colour-neutral-light-slate-100);
  }
}

body {
  font-size: 1rem;
}

p {
  line-height: 1.4;
}
