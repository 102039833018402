.account-settings {
  &__container {
    @include box;
    @include container-medium;

    display: grid;
    row-gap: var(--spacing-3);
    padding: var(--spacing-2);
  }

  &__header {
    @include level;
    @include box-header;
    margin-bottom: 0;
  }

  &__name {
    display: grid;
    row-gap: var(--spacing-2);
  }

  &__field-inline {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: var(--spacing-1);
  }

  &__users {
    display: grid;
    row-gap: var(--spacing-2);
  }

  &__user-list {
    display: grid;
    row-gap: var(--spacing-1);

    li {
      @include box;
      display: grid;
      grid-template-columns: 1fr auto auto;
      column-gap: var(--spacing-2);
      align-items: center;
      padding: 10px;

      span {
        line-height: 1.2;
      }

      button {
        @include button-reset;
        color: var(--colour-primary-leaf-100);
        font-size: var(--font-size-small);
      }
    }

    &-actions {
      @include control-group;
    }
  }
}
