.project-create {
  &__container {
    @include container-medium;

    display: grid;
    row-gap: var(--spacing-2);

    form {
      @include box;
      padding: var(--spacing-4);
      display: grid;
      row-gap: var(--spacing-3);
    }
  }

  &__header {
    @include level;
  }

  &__options {
    &--container {
      @include box;
      padding: var(--spacing-4);
      display: grid;
      row-gap: var(--spacing-3);
    }

    &--list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      column-gap: var(--spacing-2);

      button {
        @include button-reset;
        @include box(true);
        display: grid;
        align-content: flex-start;
        justify-items: center;
        padding: var(--spacing-2);

        span {
          font-weight: bold;
        }

        svg {
          margin-bottom: var(--spacing-3);
        }

        span + small {
          margin-top: var(--spacing-1);
        }
      }
    }
  }
}
