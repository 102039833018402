.accounts {
  max-width: 40rem;
  width: 100%;

  &__header {
    @include level;

    margin-bottom: var(--spacing-2);
  }

  &__item {
    @include box-large;

    margin-bottom: var(--spacing-2);

    &-header {
      @include level;
      @include box-header;

      grid-template-columns: 1fr auto auto;

      .h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    &-content {
      display: grid;
      row-gap: 1rem;

      ul {
        margin-top: var(--spacing-2);
        display: grid;
        grid-auto-flow: row;
        row-gap: var(--spacing-2);

        li {
          display: grid;
          grid-template-columns: 1fr auto auto;
          justify-content: center;
          column-gap: var(--spacing-2);

          strong {
            font-weight: bold;
            line-height: 1.4;

            min-width: 0;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          small {
            font-size: 0.75rem;
            color: var(--colour-neutral-light-slate-100);
            line-height: 1.4;
          }

          svg {
            height: 1rem;
          }
        }
      }
    }
  }

  &__empty {
    @include box-large;

    display: grid;
    place-items: center;
    justify-content: center;
    padding: 6rem;

    svg {
      height: 10rem;
      width: 10rem;
    }

    p {
      margin-top: var(--spacing-2);
      text-align: center;
    }
  }
}
