.account-sharing {
  &__container {
    @include container-medium;
  }

  &__header {
    @include level;

    margin-bottom: var(--spacing-2);
  }

  &__content {
    @include box-large;
  }

  &__invite {
    padding: var(--spacing-2) 0 var(--spacing-4) 0;

    border-bottom: 1px solid var(--colour-neutral-light-slate-25);
    display: grid;
    column-gap: var(--spacing-2);

    &.--email {
      grid-template-columns: 1fr auto;
    }

    &.--link {
      grid-template-columns: 1fr auto auto;
      align-items: center;
    }
  }

  &__links {
    margin-top: var(--spacing-4);
    display: grid;
    row-gap: var(--spacing-2);

    .header {
      color: var(--colour-neutral-light-slate-100);
    }

    &-list {
      li {
        .account-sharing__link {
          display: grid;
          grid-template-columns: 1fr auto;
          column-gap: var(--spacing-2);

          align-items: flex-start;

          &-field {
            input {
              cursor: pointer;
            }
          }
        }

        .account-sharing__status {
          margin-top: var(--spacing-1);
          display: grid;
          grid-template-columns: auto auto;
          column-gap: var(--spacing-2);
          justify-content: end;
          align-items: center;

          small {
            color: var(--colour-neutral-light-slate-100);
          }
        }

        &:not(:last-child) {
          margin-bottom: 1.25rem;
        }
      }
    }
  }
}
