@import "./basic";

.account-create {
  &__container {
    @include container-large;
    @include box-large;
  }

  &__header {
    .h2 {
      margin-bottom: var(--spacing-4);
      padding-bottom: var(--spacing-4);

      border-bottom: 1px solid var(--colour-neutral-light-slate-25);
    }
  }

  &__intro {
    margin-bottom: var(--spacing-4);
  }

  &__form {
    margin-bottom: var(--spacing-4);
    padding-bottom: var(--spacing-4);

    border-bottom: 1px solid var(--colour-neutral-light-slate-25);

    label {
      margin-bottom: 0.5rem;
      display: block;
      font-weight: bold;
    }

    &-field {
      display: grid;
      grid-template-columns: 1fr auto;
      column-gap: var(--spacing-2);
      row-gap: var(--spacing-2);

      @media screen and (max-width: 520px) {
        grid-template-columns: 1fr;
        grid-auto-flow: row;
      }
    }

    &-input {
      flex: 1;
    }

    &-help {
      @include help-inline;

      width: 17rem;
      height: 2rem;

      @media screen and (max-width: 520px) {
        width: auto;
        height: auto;

        margin-top: -0.5rem;
      }
    }
  }

  &__plans-header {
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: var(--spacing-2);
    row-gap: var(--spacing-2);

    @media screen and (max-width: 520px) {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }
  }

  &__currency {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;

    @media screen and (max-width: 520px) {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }

    &-help {
      @include help-inline;

      width: 7rem;
    }
  }

  &__plans {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    column-gap: var(--spacing-3);
    row-gap: var(--spacing-3);
    margin-top: var(--spacing-3);

    @media screen and (max-width: 520px) {
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }
  }

  &__plan {
    border: 1px solid var(--colour-neutral-light-slate-25);
    border-radius: var(--radius);

    border-top: 0.5rem solid var(--colour-primary-green-100);

    &-header {
      padding: var(--spacing-3);
      display: flex;
      flex-direction: column;
      align-items: center;

      h4 {
        letter-spacing: -0.02em;
        font-size: 2.5rem;
        font-weight: 300;
        margin-top: var(--spacing-2);

        small {
          color: var(--colour-neutral-light-slate-100);
          font-size: 0.5em;
        }
      }
    }

    &-features {
      border-top: 1px solid var(--colour-neutral-light-slate-25);

      li {
        border-bottom: 1px solid var(--colour-neutral-light-slate-25);
        padding: var(--spacing-1);
        font-size: var(--font-size-small);
      }
    }

    &-action {
      padding: var(--spacing-1);

      .message {
        margin-top: var(--spacing-1);
      }
    }
  }
}
